import {
  countryURLS,
  languageURLS,
  countryLanguageMapper,
  localIdentifierToLanguageCodeMapper,
  hreflangOptions
} from "@RHCommerceDev/resources/countries-config.json";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { urlHasPrefix } from "@RHCommerceDev/utils/urlHasPrefix";
import { getReqContext } from "./reqContext";
import { getLocaleFromPath } from "./intlUtils";

const getCountryFromUrl = (): string => {
  /*
  new URL(window.location.href) is breaking server side rendering
  for now returning ""
  we need to revisit and find a solution
  */
  const req = getReqContext();

  let urlCountry = "";
  if (processEnvServer) {
    urlCountry = req?.url?.split("/")[1];
  } else {
    const url = new URL(window?.location?.href?.toLowerCase());
    urlCountry = url?.pathname?.split("/")[1];
  }

  let countrySelection: string = "US";
  if (countryURLS.includes(`/${urlCountry}/`)) {
    countrySelection = urlCountry?.toUpperCase();
  }
  return countrySelection;
};

export const getLanguageFromUrl = () => {
  /*
  new URL(window.location.href) is breaking server side rendering
  for now returning ""
  we need to revisit and find a solution
  */
  const req = getReqContext();
  let urlLanguage = "";
  let country = "";

  if (processEnvServer) {
    urlLanguage = req?.url?.split("/")[2];
    country = req?.url?.split("/")[1];
  } else {
    const url = new URL(window?.location?.href);
    urlLanguage = url?.pathname?.split("/")[2];
    country = url?.pathname?.split("/")[1];
  }
  let languageSelection: string = "en";
  if (languageURLS.includes(`/${urlLanguage}/`)) {
    languageSelection = urlLanguage;
  }
  const isUsLanguageCountry = country === "us" || country === "ca";
  const enKey = isUsLanguageCountry
    ? countryLanguageMapper["en-US"]
    : countryLanguageMapper["en-GB"];
  const languageSelectionKey =
    languageSelection === "en"
      ? countryLanguageMapper[enKey]
      : `${urlLanguage}-${country.toUpperCase()}`;

  return {
    mapped: languageSelectionKey,
    raw: languageSelection
  };
};

export const goToSelectedCountryUrl = (country, history, language?) => {
  const url = new URL(window?.location?.href);
  const selectedLanguage: string =
    language || getLanguageFromUrl().mapped || "en-US";

  const resultPrefix = `/${country?.toLowerCase()}/${
    localIdentifierToLanguageCodeMapper?.[selectedLanguage]
  }`;
  const { rawUrl } = urlHasPrefix(url?.pathname);

  history?.push(`${resultPrefix}${rawUrl}${url.search}`);

  const chatFrame = window?.frames?.["chatFrame"];

  if (chatFrame) {
    chatFrame.src = resultPrefix?.includes("/gb/")
      ? "/chat-eu.html"
      : "/chat.html";
  }
};

export const useSelectedCountryLocalization = () => {
  const { country, language } = useUserPreferences();

  return `/${country?.toLowerCase()}/${
    localIdentifierToLanguageCodeMapper?.[language]
  }`;
};

export const getSelectedLocalization = () => {
  const country = getCountryFromUrl();
  const language = getLanguageFromUrl().mapped;

  return `/${country?.toLowerCase()}/${
    localIdentifierToLanguageCodeMapper?.[language]
  }`;
};
export const getCountryLanguageFromURL = () => {
  const req = getReqContext();

  let locale;
  if (processEnvServer) {
    locale = getLocaleFromPath(req?.url);
  } else {
    const url = new URL(window?.location?.href);
    locale = getLocaleFromPath(url?.pathname);
  }

  const matchingOption = hreflangOptions.find(
    option =>
      option.locale === locale?.locale ||
      option.locale.slice(0, -1) === locale?.locale
  );

  return matchingOption ? matchingOption.hreflang : "en-US";
};
export const getLocaleFromCountry = () => {
  const country = getCountryFromUrl();
  const language = getLanguageFromUrl().raw;
  const locale = language + "-" + country;
  return locale;
};

export default getCountryFromUrl;
