import { isEmpty } from "lodash";
import { cleanMonogramOrder } from "utils/monogramUtils";

interface Component {
  productId?: string;
  sku?: string;
  fullSkuId?: string;
  fusionId?: string;
  multiSkuId?: string;
  quantity?: number;
  brand?: string;
  giftTo?: string;
  giftFrom?: string;
  giftMessage?: string;
  spoTerms?: string | null;
  spoTermsAccepted?: boolean;
  preBillMessage?: string;
  monogram?: MonogramOrder;
  customInfo?: any | undefined;
}

interface MonogramOrder {
  fontCode?: string;
  fontColorCode?: string;
  borderCode?: string;
}

const productMapper = (
  productDetail: LineItemInput,
  monogramOrder: MonogramOrder | undefined,
  customInfo: any | undefined
) => {
  const productObj = {
    ...productDetail
  };

  if (
    monogramOrder?.fontCode ||
    monogramOrder?.fontColorCode ||
    monogramOrder?.borderCode
  ) {
    productObj.monogram = cleanMonogramOrder(monogramOrder);
  }

  if (!isEmpty(customInfo)) {
    productObj.customInfo = customInfo;
  }

  return productObj;
};

export default productMapper;
