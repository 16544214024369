import cloneDeep from "lodash.clonedeep";
import { MonogramOrderProps } from "page-monogram-dialog";

export const getBorder = (
  borders: MonogramBorders[],
  borderId: string = ""
) => {
  const id = borderId || borders[0]?.id;
  return borders?.find(b => b.id === id);
};

export const getBorders = (font: MonogramBaseFields) => {
  const borders = font?.borders?.map(b => b.style);
  return borders as MonogramBorders[];
};

export const getColor = (
  colors: MonogramBaseFields[],
  colorId: string = ""
) => {
  const id = colorId || colors[0]?.id;
  return colors?.find(c => c.id === id);
};

export const getFont = (
  fonts: Array<MonogramBaseFields>,
  fontId: string = ""
) => {
  if (fonts?.length) {
    const id = fontId || fonts[0]?.id;
    return fonts?.find(f => f.id === id);
  }
};

export const validateMonogramOrder = (
  hasBorders: number,
  hasColors: number,
  order: MonogramOrderProps,
  min: number,
  max: number,
  errorMessages: {
    textInput: string;
    specialSymbol: string;
  }
) => {
  const isSpecialCharacterAllowed = order.fontId
    ? !["404004", "404005"].includes(order.fontId)
    : true;
  let usrMsg = "";
  const allowedInputWithSpecialChars = /^[a-zA-Z0-9 !@&\-,;':"/.]+$/;
  const allowedInputWithoutSpecialChrs = /^[a-zA-Z0-9]+$/;
  let hasSpecialCharacterCheckFailed = false;
  let hasAllowedInputCheckFailed = false;

  Object.entries(order).forEach(o => {
    const key = o[0];
    const value = o[1];

    if (
      key === "lines" &&
      (value[0] === undefined ||
        !!value?.filter(
          (v: string) =>
            min === max &&
            v.length !== max &&
            (v.length <= max || v.length >= min)
        ).length)
    ) {
      const length = min === max ? max : `${min} to ${max}`;
      usrMsg = `Please enter ${length} characters`;
    } else if (
      key === "lines" &&
      value
        ?.map((v: string) => v.trim())
        .some((element: string) => {
          hasAllowedInputCheckFailed = isSpecialCharacterAllowed
            ? !allowedInputWithSpecialChars.test(element)
            : false;
          hasSpecialCharacterCheckFailed = !isSpecialCharacterAllowed
            ? !allowedInputWithoutSpecialChrs.test(element)
            : false;
          if (
            max <= 3 &&
            (hasAllowedInputCheckFailed || hasSpecialCharacterCheckFailed)
          ) {
            return true;
          }
          return hasAllowedInputCheckFailed || hasSpecialCharacterCheckFailed;
        })
    ) {
      usrMsg = hasAllowedInputCheckFailed
        ? errorMessages.textInput
        : errorMessages.specialSymbol;
    } else if (key === "colorId" && value === "" && hasColors) {
      usrMsg = "Please select a color.";
    } else if (key === "borderId" && value === "" && hasBorders) {
      usrMsg = "Please select a border.";
    } else if (key === "fontId" && value === "") {
      usrMsg = "Please select a font.";
    }
  });

  return usrMsg;
};

// Remove display names before adding to product line item for order
export const cleanMonogramOrder = (order: MonogramOrderProps) => {
  const cleanOrder = cloneDeep(order);

  if (cleanOrder) {
    Object.keys(cleanOrder).forEach(key => {
      if (
        key.includes("Name") ||
        (key.includes("Id") && key !== "styleId") ||
        key.includes("__typename")
      ) {
        delete cleanOrder[key];
      }
    });
    return cleanOrder;
  }
  return {};
};
