import {
  CustomProductConfiguration,
  CustomProductMapper
} from "component-custom-product";

export interface FractionMapper {
  value?: number;
  display?: string;
}

export const gcd = (a: number, b: number): number => {
  if (b) return gcd(b, a % b);
  return Math.abs(a);
};

export const fractionCalculator = (
  n: number,
  prec: number,
  up: number
): string => {
  const s = String(n),
    p = s.indexOf(".");
  if (p == -1) return s;

  let i: any = Math.floor(n) || "";
  const dec: any = s.substring(p),
    m = prec || Math.pow(10, dec.length - 1),
    num = up === 1 ? Math.ceil(dec * m) : Math.round(dec * m),
    den = m,
    g = gcd(num, den);

  if (den / g == 1) return String(i + num / g);

  if (i) i = i + " and  ";
  return i + String(num / g) + "/" + String(den / g);
};

export const getLengthOptions = (max: number, min: number): number[] => {
  let lengthsArr: number[] = [];
  for (let i = min; i <= max; i++) {
    lengthsArr?.push(i);
  }
  return lengthsArr;
};

export const getWidthOptions = (max: number, min: number): number[] => {
  let widthsArr: number[] = [];
  for (let i = min; i <= max; i++) {
    widthsArr?.push(i);
  }
  return widthsArr;
};

export const getWidthGapOptions = (base: number): FractionMapper[] => {
  let widthGapArr: FractionMapper[] = [];
  for (let i = 1; i <= 7; i++) {
    const decimal = base * i;
    if (decimal < 1) {
      widthGapArr?.push({
        value: decimal,
        display: fractionCalculator(decimal, 0, 0)
      });
    }
  }
  return widthGapArr;
};
export const getLengthGapOptions = (base: number): FractionMapper[] => {
  let lengthGapArr: FractionMapper[] = [];
  for (let i = 1; i <= 7; i++) {
    const decimal = base * i;
    if (decimal < 1) {
      lengthGapArr?.push({
        value: decimal,
        display: fractionCalculator(decimal, 0, 0)
      });
    }
  }
  return lengthGapArr;
};

export const SUB_PANEL_OPTIONS = ["Left", "Right", "Center"];

export const PANELS = ["Pair", "Single"];

export const getFormattedMeasurement = (
  width: number,
  fraction: number
): string => {
  let displayMeasurementValue = width?.toString();
  if (fraction > 0) {
    const displayFraction = fractionCalculator(fraction, 0, 0);
    displayMeasurementValue = displayFraction
      ? `${displayMeasurementValue} ${displayFraction}`
      : displayMeasurementValue;
  }
  return `${displayMeasurementValue}&#34;`;
};

export const getFormattedWindowLabel = (
  roomLabel: string,
  roomLabelDescription: string
): string => {
  let label = roomLabel;
  if (roomLabelDescription?.trim()?.length) {
    label = `${roomLabel} ${roomLabelDescription}`;
  }
  return label;
};

export const KEY_GROUP_MAPPERS = {
  drape: {
    group: "custom-drapes-modals",
    measurements: {
      header: "Measurements",
      val: "measurements"
    },
    panels: {
      header: "Panels",
      val: "panels"
    },
    linings: {
      header: "Lining",
      val: "lining"
    },
    label: {
      header: "Label This Window",
      val: "label"
    }
  },
  shade: {
    group: "custom-shades-modals",
    mountType: {
      header: "Mount Type",
      val: "shades-mount"
    },
    measurementsRS: {
      header: "Measurements",
      val: "shades-measurements-roman"
    },
    measurementsRO: {
      header: "Measurements",
      val: "shades-measurements-roller"
    },
    measurementsWD: {
      header: "Measurements",
      val: "shades-measurements-wood"
    },
    controlType: {
      header: "Control Type",
      val: "shades-control-type-roman"
    },
    controlPosition: {
      header: "Control Position",
      val: "shades-position-roman"
    },
    linings: {
      header: "Lining",
      val: "shades-lining"
    },
    label: {
      header: "Label This Window",
      val: "shades-label"
    },
    tiltPosition: {
      header: "Control And Tilt Position",
      val: "shades-control-tilt"
    },
    tiltType: {
      header: "Tilt Type",
      val: "shades-tilt-type"
    },
    hardwareFinish: {
      header: "Hardware Finish",
      val: "shades-bracket-color"
    },
    rollType: {
      header: "Roll Type",
      val: "shades-roller-type"
    }
  },
  customHardware: {
    group: "custom-hardware-modals",
    size: {
      header: "Size",
      val: "size"
    },
    rodType: {
      header: "Rod Type",
      val: "rod-type"
    },
    bracketShape: {
      header: "Bracket Shape",
      val: "bracket-shape"
    },
    includeRings: {
      header: "Include Rings",
      val: "include-rings"
    },
    label: {
      header: "Label This Window",
      val: "label"
    }
  },
  trackHardware: {
    group: "custom-track-hardware-modals",
    finish: {
      header: "Finish",
      val: "finish"
    },
    length: {
      header: "Measurement",
      val: "measurements"
    },
    panels: {
      header: "Panels",
      val: "panels"
    },
    controlType: {
      header: "Control Type",
      val: "control-type"
    },
    mountType: {
      header: "Mount Type",
      val: "mount"
    },
    label: {
      header: "Label This Window",
      val: "label"
    }
  }
};

export const KEY_GROUP_MAPPER_FAQ = {
  drape: {
    key: "drapes-faq",
    group: "custom-drapes-modals"
  },
  shade: {
    group: "custom-shades-modals",
    key: "shades-faq"
  },
  customHardware: {
    group: "custom-hardware-modals",
    key: "hardware-faq"
  },
  trackHardware: {
    group: "custom-track-hardware-modals",
    key: "track-hardware-faq"
  }
};

const CUSTOM_HARDWARE = [
  {
    id: "",
    key: "length",
    optionType: "Length",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "roomLabel",
    optionType: "Window Label",
    label: "",
    sortPriority: 0
  }
];

const CUSTOM_TRACK_HARDWARE = [
  {
    id: "",
    key: "length",
    optionType: "Length",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "panel",
    optionType: "Panel",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "controlType",
    optionType: "Control Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "mountType",
    optionType: "Mount Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "roomLabel",
    optionType: "Window Label",
    label: "",
    sortPriority: 0
  }
];

const CUSTOM_DRAPE = [
  {
    id: "",
    key: "panel",
    optionType: "Panel",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "finish",
    optionType: "Finish",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "width",
    optionType: "Width",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "length",
    optionType: "Length",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "roomLabel",
    optionType: "Window Label",
    label: "",
    sortPriority: 0
  }
];

const CUSTOM_SHADE = [
  {
    id: "",
    key: "width",
    optionType: "Width",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "length",
    optionType: "Length",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "mountType",
    optionType: "Mount Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "controlLength",
    optionType: "Control Length",
    label: "",
    sortPriority: 0,
    unit: "inches"
  },
  {
    id: "",
    key: "controlType",
    optionType: "Control Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "tiltType",
    optionType: "Tilt Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "controlPosition",
    optionType: "Control Position",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "controlAndTilt",
    optionType: "Control & Tilt Position",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "bracketColor",
    optionType: "Hardware Finish",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "rollType",
    optionType: "Roll Type",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "lining",
    optionType: "Lining",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "roomLabel",
    optionType: "Window Label",
    label: "",
    sortPriority: 0
  }
];

const CUSTOM_RUG = [
  {
    id: "",
    key: "trim",
    optionType: "Trim",
    label: "",
    sortPriority: 0
  },
  {
    id: "",
    key: "width",
    optionType: "Width",
    label: "",
    sortPriority: 0,
    unit: "feet"
  },
  {
    id: "",
    key: "length",
    optionType: "Length",
    label: "",
    sortPriority: 0,
    unit: "feet"
  },
  {
    id: "",
    key: "diameter",
    optionType: "Diameter",
    label: "",
    sortPriority: 0,
    unit: "feet"
  }
];

const CUSTOM_PRODUCT_TYPE_MAPPER = {
  hardware: CUSTOM_HARDWARE,
  drape: CUSTOM_DRAPE,
  shade: CUSTOM_SHADE,
  trackHardware: CUSTOM_TRACK_HARDWARE,
  rug: CUSTOM_RUG
};

const getMappedMeasurement = (unit: string, value: number) => {
  const whole = Math.floor(value);
  const fraction = value - whole;
  if (unit === "feet") {
    const feet = Math.floor(value / 12);
    const inches = value % 12;

    return `${feet}&#39; ${inches}&#34;`;
  } else {
    return `${getFormattedMeasurement(whole, fraction)}`;
  }
};

const mapCustomFields = (
  skuCustomization: SkuCustomizationType,
  options: Maybe<LineItemOptionType[]>
): ProductItemOption[] => {
  const customOptions: ProductItemOption[] = [];
  (CUSTOM_PRODUCT_TYPE_MAPPER[skuCustomization?.type || ""] || []).forEach(
    (item, index) => {
      const labelValue = skuCustomization[item.key];
      if (Boolean(labelValue)) {
        customOptions.push({
          id: (options?.length + index)?.toString() ?? "",
          label: item.unit
            ? getMappedMeasurement(item.unit, labelValue)
            : labelValue?.toString() ?? "",
          optionType: item.optionType ?? "",
          sortPriority: options?.length + index
        });
      }
    }
  );
  return customOptions;
};

export const rebuildCustomOptions = (
  skuCustomization: SkuCustomizationType,
  options: Maybe<LineItemOptionType[]>
): ProductItemOption[] => {
  switch (skuCustomization?.type) {
    case "hardware":
      return [...mapCustomFields(skuCustomization, options)];

    case "shade":
      return [...mapCustomFields(skuCustomization, options)];

    case "drape":
      return [...mapCustomFields(skuCustomization, options)];

    case "trackHardware":
      return [...mapCustomFields(skuCustomization, options)];

    case "rug":
      return [...mapCustomFields(skuCustomization, options)];

    default:
      return [];
  }
};

interface Options {
  configuration: CustomProductConfiguration;
  pli: ProductLineItem;
}

export const clearCustomizationInput = (
  object: CustomProductMapper | SkuCustomizationType
): SkuCustomizationInput => {
  const newObject: SkuCustomizationType = {};
  Object.keys(object).forEach(key => {
    if (object[key]) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};

// custom hardware fields mapper
export const getMappedCustomHardwareFields = ({
  configuration,
  pli
}: Options): SkuCustomizationType => {
  return clearCustomizationInput({
    type: "hardware",
    length: configuration?.length,
    fulfillmentCode:
      pli?.customProductOptions?.customProductInformation
        ?.cwCustomProductCode ?? ""
  });
};

// custom shade fields mapper
export const getMappedShadeFields = ({
  configuration,
  pli
}: Options): SkuCustomizationType => {
  return clearCustomizationInput({
    type: "shade",
    mountType: configuration?.mountTypes?.value,
    controlType: configuration?.controlTypes?.value,
    controlPosition: configuration?.controlPositions?.value,
    controlLength: configuration?.controlLength,
    lining: configuration?.linings?.value,
    bracketColor: configuration?.bracketColors?.value,
    rollType: configuration?.rollTypes?.value,
    tiltType: configuration?.tiltTypes?.value,
    controlAndTilt: configuration?.controlsAndTilts?.value,
    width: configuration?.width,
    length: configuration?.length,
    fulfillmentCode:
      pli?.customProductOptions?.customProductInformation
        ?.cwCustomProductCode ?? ""
  });
};

// custom drape field mapper
export const getMappedDrapeFields = ({
  configuration,
  pli
}: Options): SkuCustomizationType => {
  return clearCustomizationInput({
    type: "drape",
    width: configuration?.width,
    length: configuration?.length,
    panel: configuration?.panels?.code
      ? configuration?.panels?.code
      : configuration?.panels?.value,
    finish: configuration?.finish?.value,
    fulfillmentCode:
      pli?.customProductOptions?.customProductInformation
        ?.cwCustomProductCode ?? ""
  });
};

// custom track hardware fields mapper
export const getMappedTrackHardwareFields = ({
  configuration,
  pli
}: Options): SkuCustomizationType => {
  return clearCustomizationInput({
    type: "trackHardware",
    panel: configuration?.panels?.code
      ? configuration?.panels?.code
      : configuration?.panels?.value,
    controlType: configuration?.controlTypes?.value,
    mountType: configuration?.mountTypes?.code
      ? configuration?.mountTypes?.code
      : configuration?.mountTypes?.value,
    length: configuration?.length,
    fulfillmentCode:
      pli?.customProductOptions?.customProductInformation
        ?.cwCustomProductCode ?? ""
  });
};

export const getCustomProductConfig = ({
  configuration,
  pli
}: Options): SkuCustomizationType => {
  let customConfig: SkuCustomizationType = {
    roomLabel: getFormattedWindowLabel(
      configuration?.roomLabel || "",
      configuration?.roomLabelDescription || ""
    )
  };

  switch (
    pli?.customProductOptions?.customProductInformation?.customProductType
  ) {
    case "customHardware":
      customConfig = {
        ...customConfig,
        ...getMappedCustomHardwareFields({ configuration, pli })
      };
      break;
    case "shade":
      customConfig = {
        ...customConfig,
        ...getMappedShadeFields({ configuration, pli })
      };
      break;
    case "drape":
      customConfig = {
        ...customConfig,
        ...getMappedDrapeFields({ configuration, pli })
      };
      break;
    case "trackHardware":
      customConfig = {
        ...customConfig,
        ...getMappedTrackHardwareFields({ configuration, pli })
      };
      break;
  }
  return customConfig;
};

export const filterCustomRugOptionTypes = lineItem => {
  if (
    lineItem?.customProductOptions?.customProductInformation
      ?.customProductType === "rug" &&
    !lineItem?.customProductOptions?.customProductInformation?.showSizeDropDown
  ) {
    // Filter out the 'Size' option from availableOptions
    const filteredOptions = lineItem?.availableOptions?.filter(
      option => option?.type !== "Size"
    );
    return {
      ...lineItem,
      availableOptions: filteredOptions
    };
  }
  return lineItem;
};
